@font-face {
  font-family: "Aquire";
  src: local("Aquire"), url("./assets/fonts/Aquire.otf") format("opentype");
}

@font-face {
  font-family: "Exon";
  src: local("Exon"), url("./assets/fonts/Exon.otf") format("opentype");
}

body {
  margin: 0;
  font-family: "Exon", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8ff;
}

code {
  font-family: "Exon", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
